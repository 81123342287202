import React, { useEffect, useState } from "react";
import { toCommas } from "../../utils";
import styles from "./Dashboard.module.css";
// import { useHistory, useLocation } from "react-router-dom";
import Empty from "../svgIcons/Empty";
import Chart from "./Chart";
// import Donut from './Donut'
import moment from "moment";
import { Check, Pie, Bag, Card, Clock, Frown } from "./Icons";
import Spinner from "../Spinner/Spinner";
import apiClient from "./../../api/client";
import { startOfWeek, endOfWeek } from "date-fns";
import DatePicker from "react-date-picker";
import { Pagination } from "@mui/material";

const Revenue = () => {
  const [weeklySales, setWeeklySales] = useState([]);
  const [pendingOrder, setPendingOrder] = useState();
  const [value1, onChange1] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  const [modal, setModal] = useState();

  useEffect(() => {
    fetchWeeklyOrders();
    getPendingOrders();
  }, [modal]);

  const getPendingOrders = async () => {
    const { data } = await apiClient.get("/orders/getPendingOrders");
    setPendingOrder(data);
  };

  const fetchWeeklyOrders = async () => {
    
    const { data } = await apiClient.get("/orders/getsalesdaterange", {
      startDate: value1,
      endDate: value2,
    });
    
    setWeeklySales(data.monthlySales);
  };
  var total = 0;
  weeklySales?.map((order) => {
    order.orderItems.map((item) => {
      total = total + item?.price * item?.qty;
    });
  });

  var totalOrderPending = 0;
  weeklySales?.map((order) => {
    if (
      order.deliveryStatus !== "Delivered" ||
      order.deliveryStatus !== "Cancelled"
    ) {
    }
  });

  var totalProfit = 0;

  weeklySales?.map((order) => {
    order.orderItems.map((item) => {
      if (item.product?.cost_price || item.aquariumProduct?.cost_price) {
        totalProfit =
          totalProfit +
          (Number(item?.price) -
            Number(
              item?.product
                ? item?.product?.cost_price
                : item?.aquariumProduct?.cost_price
            )) *
            Number(item?.qty);
      }
    });
  });

  return (
    <div id="layoutSidenav_content">
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
          <li
            className={styles.listItem}
            style={{ backgroundColor: "#1976d2", color: "white" }}
          >
            <div>
              <p>₹ {toCommas(total.toFixed(0))} </p>
              <h2 style={{ color: "white" }}> Sales</h2>
            </div>
            <div>
              <Check />
            </div>
          </li>

          <li className={styles.listItem}>
            <div>
              <p>₹ {toCommas(totalProfit)} </p>
              <h2> Profit</h2>
            </div>
            <div>
              <Pie />
            </div>
          </li>
        </ul>
      </section>

      <section
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "50%",
          margin: "0 auto",
          alignItems: "center",
        }}
      >
        <div>
          <div className="start-div">
            <p>Start Date</p>
            <DatePicker value={value1} onChange={onChange1} />
          </div>
        </div>
        <div>
          <div>
            <p>End Date</p>
            <DatePicker value={value2} onChange={onChange2} />
          </div>
        </div>
        <button
          style={{
            padding: "10px",
            border: "none",
            backgroundColor: "rgb(25, 118, 210)",
            fontWeight: "500",
            borderRadius: "15px",
            color: "white",
            width: "100px",
            height: "40px",
            alignSelf: "end",
            cursor: "pointer",
          }}
          onClick={() => fetchWeeklyOrders()}
        >
          Check
        </button>
      </section>

      <section>
        <h1 style={{ textAlign: "center", padding: "30px" }}>Orders</h1>
        <div>
          <div className={styles.table}>
            <table>
              <tbody>
                {weeklySales.length !== 0 && (
                  <tr>
                    <th style={{ padding: "15px" }}>#orderId</th>
                    <th style={{ padding: "15px" }}>Customer Name</th>
                    <th style={{ padding: "15px" }}>Payment Method</th>
                    <th style={{ padding: "15px" }}>Date</th>
                    <th style={{ padding: "15px" }}>Status</th>
                    <th style={{ padding: "15px" }}>Slot</th>
                    <th style={{ padding: "15px" }}>Total Amount</th>
                  </tr>
                )}

                {weeklySales.map((record) => (
                  <tr className={styles.tableRow} key={record._id}>
                    <td>{record._id}</td>
                    <td>{record.user?.name}</td>
                    <td>{record.paymentMethod}</td>
                    <td>{new Date(record.updatedAt).toDateString()}</td>
                    {record.deliveryStatus === "Processing" && (
                      <td
                        style={{
                          backgroundColor: "yellow",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Delivered" && (
                      <td
                        style={{
                          backgroundColor: "#50C878",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Cancelled" && (
                      <td
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    {record.deliveryStatus === "Out for Delivery" && (
                      <td
                        style={{
                          backgroundColor: "#0096FF",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {record.deliveryStatus}
                      </td>
                    )}
                    <td>{record?.deliverySlot}</td>
                    <td>₹ {record?.totalPrice} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Revenue;
