import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

const AddCatBanner = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [photo, setPhoto] = useState();
  const [bg, setBg] = useState();
const [product, setProduct] = useState()
const [productarr, setProductarr] = useState([])
  const [cat, setCat] = useState();
  const [category, setCategory] = useState();
  const [categoryId, setCategoryId] = useState([]);
  const [subcat, setSubCat] = useState();
  const [subcatId, setSubCatId] = useState([]);
  const [opt, setOpt] = useState();
  const [catId, setCatId] = useState([]);
  const opt1 = ["Category","Sub Category", "Special Category", "Product"]
  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    fetchCategory()
  }, []);

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-special-categories");
    setCat(data);
  };
  const fetchSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-categories");
    setSubCat(data);
  };
  const fetchCategory = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCategory(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Banner",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const formData = new FormData();

        formData.append("image", photo, photo.name);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await apiClient.post(
          "/uploadSingleImage/uploadSingleImage",
          formData,
          config
        );

        const result = await apiClient.post("/catbanner/create", {
          title: name,
          img: data,
          specialcategory: catId,
          product: productarr,
          subcategory: subcatId,
          category: categoryId
        });
        if (result.ok) {
          setName("");
          setDescription("");
          setPhoto("");
          setBg("");
          swal("Sucess");
          navigate("/cat-banner");
        } else {
          swal("Error Retry!");
        }
      }
    });
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };
  const handleSelect = async (e) => {
    setCatId(e);
  };
  const handleSelect1 = async (e) => {
    setCatId([])
    setSubCatId([])
    setProductarr([])
    setCategoryId([])
    setOpt(e);
  };
  const handleSelect2 = async (e) => {
    
    setSubCatId(e);
  };
  const handleSelect3 = async (e) => {
    
    setCategoryId(e);
  };
  const handleSubmit1= async (e) => {
    setProductarr([...productarr, product])
    setProduct("")
    
   
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Category</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/banners/image")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Banners</a>
            </li>
            <li className="breadcrumb-item active">Image</li>
          </ol>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Banner</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-4 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Title (*for reference)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>

                    <br />
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Select Image for Banner
                        </label>
                        <form>
                          <input type="file" onChange={uploadFileHandler} />
                        </form>
                      </div>
                    </div>
                    <br />
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Select Banner Type
                        </label>
                    <Select
                          className="form-control"
                      
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={opt}
                          label="Select Banner Type"
                          // onChange={(e) => setCatId(e.target.value)}
                          onChange={(e) => handleSelect1(e.target.value)}
                          // renderValue={(selected) => (
                          //   <Box
                          //     sx={{
                          //       display: "flex",
                          //       flexWrap: "wrap",
                          //       gap: 0.5,
                          //     }}
                          //   >
                          //     {selected.map((item) => (
                          //       <Chip key={item} label={item} />
                          //     ))}
                          //   </Box>
                          // )}
                          MenuProps={MenuProps}
                        >
                          {opt1?.map((item, index) => {
                            return (
                              <MenuItem value={item}>{item}</MenuItem>
                            );
                          })}
                        </Select>
                        </div>
                    </div>
                    <br />
                    {opt === "Special Category" ? (<div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Choose Multiple Special Categories (*Max 6)
                        </label>

                        <Select
                          className="form-control"
                          multiple
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={catId}
                          label="Category"
                          // onChange={(e) => setCatId(e.target.value)}
                          onChange={(e) => handleSelect(e.target.value)}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((item) => (
                                <Chip key={item} label={item} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {cat?.map((item, index) => {
                            return (
                              <MenuItem value={item._id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>) : (opt === "Sub Category" ? 
                    (<div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Choose Multiple Sub Categories (*Max 6)
                        </label>

                        <Select
                          className="form-control"
                          multiple
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={subcatId}
                          label="Category"
                          // onChange={(e) => setCatId(e.target.value)}
                          onChange={(e) => handleSelect2(e.target.value)}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((item) => (
                                <Chip key={item} label={item} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {subcat?.map((item, index) => {
                            return (
                              <MenuItem value={item._id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>) : (opt === "Product" ? (<div className="col-lg-4 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Product ID (Add One by One (*Max 6))
                        </label>
                        
                        <input
                          type="text"
                          style={{marginBottom: "5px"}}
                          className="form-control"
                          placeholder="Product ID"
                          name="product"
                          value={product}
                          onChange={(e) => {setProduct(e.target.value)
                          }}
                        />
                        
                        {productarr?.map((item) => (
                                <Chip key={item} label={item} />
                              ))}
                             <br/>
                        <input style={{marginTop: "5px"}} type="button" value="Add Products" onClick={() => handleSubmit1()}/>
                    
                      </div>

                    </div>): (opt === "Category" ? (<div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Choose Multiple Categories (*Max 6)
                        </label>

                        <Select
                          className="form-control"
                          multiple
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={categoryId}
                          label="Category"
                          // onChange={(e) => setCatId(e.target.value)}
                          onChange={(e) => handleSelect3(e.target.value)}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((item) => (
                                <Chip key={item} label={item} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {category?.map((item, index) => {
                            return (
                              <MenuItem value={item._id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>) : (<br />))))}
                    
                    <br />

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddCatBanner;
