import React, { useState } from "react";
import { Button,
  MenuItem,
  Select,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton, } from "@material-ui/core";
  import Paper from "@material-ui/core/Paper";
import swal from "sweetalert";
import DatePicker from "react-date-picker";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
const intialState = {
  jobs: [{ slot: "", quantity: ""}],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));
const AddDeliverySlot = () => {
  const classes = useStyles();
  const [name, setName] = useState();
  const [value1, onChange1] = useState(new Date());
  const [jobsData, setJobsData] = useState(intialState);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(value1)
    swal({
      title: "Are you sure?",
      text: "You want to Add New Slot",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        
        const result = await apiClient.post("/variations/deliveryslot", {
          slots: jobsData.jobs,
          deliveryDate:  value1
        });
        if (result.ok) {
          swal("Success");
        }
      
   
        setName("");
        navigate("/list-deliveryslot");
      }
    });
  };

  const navigate = useNavigate();


  const handleChange = (index, e) => {
    const values = [...jobsData.jobs];
    values[index][e.target.name] = e.target.value;
    setJobsData({ ...jobsData, jobs: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, { slot: "", quantity: ""}],
    }));
  };

  const handleRemoveField = (index) => {
    const values = jobsData.jobs;
    values.splice(index, 1);
    setJobsData((prevState) => ({ ...prevState, values }));
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Slot</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Variations</a>
            </li>
            <li className="breadcrumb-item active">Add Slot</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Slot</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                  
                    <div className="col-lg-3 col-md-2">
                    <div className="start-div">
            
            <DatePicker value={value1} onChange={onChange1} />
          </div>
                      {/* <div className="form-group">
                        <label className="form-label">Slot*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Eg - 12am - 2pm"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div> */}
                      
                    </div>
                    <div>
                        <TableContainer
                          component={Paper}
                          className="tb-container"
                        >
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Slot</TableCell>
                                <TableCell>Quantity</TableCell>
                               

                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {jobsData?.jobs?.map((itemField, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left">
                                    <InputBase
                                      sx={{ ml: 1, flex: 1 }}
                                      type="text"
                                      name="slot"
                                      onChange={(e) => handleChange(index, e)}
                                      value={itemField.slot}
                                      placeholder="Eg. 12pm - 2pm"
                                    />
                                  </TableCell>
                                  
                                  <TableCell align="left">
                                    <InputBase
                                      sx={{ ml: 1, flex: 1 }}
                                      type="number"
                                      name="quantity"
                                      onChange={(e) => handleChange(index, e)}
                                      value={itemField.quantity}
                                      placeholder="No of delivery in slot"
                                    />
                                  </TableCell>

                                  <TableCell align="left">
                                    <IconButton
                                      onClick={() => handleRemoveField(index)}
                                    >
                                      <DeleteOutlinedIcon
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="addButton">
                          <button onClick={handleAddField}>+</button>
                        </div>
                      </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddDeliverySlot;
