import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import apiClient from "./../../api/client";
import { Pagination } from "@mui/material";
import Select from "react-select";
import * as XLSX from "xlsx";

const options = [
  { value: "Processing", label: "Processing" },
  { value: "Out for Delivery", label: "Out for Delivery" },
  { value: "Delivered", label: "Delivered" },
  { value: "Cancelled", label: "Cancelled" },
];

const OrderFilter = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);

  const [data, setData] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [value1, onChange1] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const totalPages = Math.ceil(getList?.length / pageSize);
  const lastIndex = pageSize * currentPageNo;
  const firstIndex = lastIndex - pageSize;

  useEffect(() => {
    fetchProducts();
  }, [currentPageNo]);

  const fetchProducts = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/orders/orderfilter", {
      startDate: value1,
      endDate: value2,
      status: selectedOption?.value,
    });

    setGetList(data.monthlySales);
    setData(data?.monthlySales.slice(firstIndex, lastIndex));
    setIsLoaded(false);
  };

  const downloadreport = () => {
    let arr = [];

    const flattenObj = (ob) => {
      let result = {};
      for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i]);
          delete temp.address;
          delete temp.country;
          delete temp.email;
          // delete temp.mobileNumber;
          delete temp.region;
          delete temp.zone;
          delete temp._id;

          for (const j in temp) {
            result[i + "." + j] = temp[j];
          }

          // result[i] = JSON.stringify(ob[i]);
        } else if (
          !Array.isArray(ob[i]) &&
          i != "updatedAt" &&
          i != "taxPrice" &&
          i != "__v"
        ) {
          // const nestedArr = ob[i];
          // nestedArr.forEach((obj, index) => {
          //   delete obj.image;
          //   const abc = flattenObj(obj);
          //   result[i + "." + index] = JSON.stringify(abc);
          // });

          if (i === "createdAt") {
            result[i] = new Date(ob[i]).toDateString();
          } else {
            result[i] = ob[i];
          }
        }
      }
      return result;
    };
    for (let i = 0; i < getList?.length; i++) {
      const newobj = flattenObj(getList[i]);
      arr.push(newobj);
    }

    setTimeout(() => {
      const data1 = arr;
      const worksheet = XLSX.utils.json_to_sheet(data1, {
        header: [
          "_id",
          "user.name",
          "shippingAddress.mobileNumber",
          "deliverySlot",
          "paymentMethod",
          "itemsPrice",
          "shippingPrice",
          "deliveryCharge",
          "totalPrice",
          
          "shippingAddress.address",
          "shippingAddress.street",
          "shippingAddress.area",
          "shippingAddress.landmark",
          "shippingAddress.pincode",
          "shippingAddress.city",
          
         
          
        ],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Orders.xlsx");
    }, 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Orders</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Orders Filtering</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <section
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "50%",
                  margin: "0 auto",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className="start-div">
                    <p>Start Date</p>
                    <DatePicker value={value1} onChange={onChange1} />
                  </div>
                </div>
                <div>
                  <div>
                    <p>End Date</p>
                    <DatePicker value={value2} onChange={onChange2} />
                  </div>
                </div>
              </section>

              <div className="col-lg-3 col-md-2">
                <div className="form-group">
                  {/* <label className="form-label">Select Status to Update</label> */}
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    name="selectedOption"
                    placeholder="Order Status"
                  />
                </div>
              </div>
              <div>
                <button
                  style={{
                    padding: "10px",
                    border: "none",
                    backgroundColor: "rgb(25, 118, 210)",
                    fontWeight: "500",
                    borderRadius: "15px",
                    color: "white",
                    width: "100px",
                    height: "40px",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                  onClick={fetchProducts}
                >
                  Check
                </button>
              </div>
              <div>
                <button
                  style={{
                    padding: "10px",
                    border: "none",
                    backgroundColor: "rgb(25, 118, 210)",
                    fontWeight: "500",
                    borderRadius: "15px",
                    marginLeft: "10px",
                    color: "white",
                    width: "100px",
                    height: "40px",
                    alignSelf: "end",
                    cursor: "pointer",
                  }}
                  onClick={downloadreport}
                >
                  Download Report
                </button>
              </div>
            </div>
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Orders</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>#Order Id</th>
                        <th>#Order Placed Date</th>
                        <th>Status</th>
                        <th>Delivery Slot</th>

                        <th>Customer Name</th>
                        <th>Total Amount</th>

                        <th>Info</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? data?.map((row, index) => {
                            const date = new Date(row.updatedAt).toDateString();
                            const time = new Date(
                              row.updatedAt
                            ).toLocaleTimeString();

                            const status = row.deliveryStatus;

                            return (
                              <>
                                <tr key={index}>
                                  <td>{row._id}</td>
                                  <td>{date}</td>
                                  {status === "Processing" && (
                                    <td
                                      style={{
                                        backgroundColor: "yellow",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Delivered" && (
                                    <td
                                      style={{
                                        backgroundColor: "#50C878",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Cancelled" && (
                                    <td
                                      style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}
                                  {status === "Out for Delivery" && (
                                    <td
                                      style={{
                                        backgroundColor: "#0096FF",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {row.deliveryStatus}
                                    </td>
                                  )}

                                  <td>{row?.deliverySlot}</td>
                                  <td>{row?.user?.name}</td>
                                  <td>₹{row?.totalPrice}</td>

                                  <td>
                                    <Link to="/orders/detail" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                  <td className="action-btns">
                                    <Link to="/orders/update" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td>
                                  {/* <td>
                                    <Link to="/orders/delivery" state={row}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link>
                                  </td> */}
                                </tr>
                              </>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          count={totalPages}
          page={currentPageNo}
          siblingCount={1}
          color="primary"
          onChange={(e, value) => setCurrentPageNo(value)}
        />
      </main>
    </div>
  );
};

export default OrderFilter;
