import { Button } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import apiClient from "../../api/client";
import swal from "sweetalert";

function CreateCustomer() {
 
 

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [verificationCode, setVerificationCode] = useState();
  const [phone, setPhone] = useState();

  const handleSubmit = async () => {
    const result = await apiClient.post("/users/create", {
      name,
      verificationCode,
      phone
    });
    if (!result.ok) swal("User Not Created");
    else {
      swal("Success");
      setName("");
      setVerificationCode();
      setPhone();
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Create User</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/users/list")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              {/* <a href="/admin/product/create">Users</a> */}
            </li>
            <li className="breadcrumb-item active"></li>
          </ol>

          {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>Create User</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Customer Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <br />
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">Verification Code</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="verificationCode"
                            name="verificationCode"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </main>
    </div>
  );
}

export default CreateCustomer;
