import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "./../api/client";
import { Button, Typography, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import * as XLSX from "xlsx";

const AllProducts = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

  const [brands, setBrands] = useState();
  const [brandId, setBrandId] = useState();
  const [totcount, setTotCount] = useState();
  const [oof, setOof] = useState();

  const [cat, setCat] = useState();
  const [catId, setCatId] = useState();
  const [outOfStock, setOutOfStock] = useState(false);

  useEffect(() => {
    fetchProducts();
    fetchBrands();
    fetchCategories();
    fetchCount()
  }, [currentPageNo, del, outOfStock]);

  const handleOutofStock = async () => {
    const { data } = await apiClient.get("/products/outofstockproducts", {
      pageNumber: currentPageNo,
    });

    setPageCount(data.pageCount);
    setGetList(data.products);
    setIsLoaded(false);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const fetchBrands = async () => {
    const { data } = await apiClient.get("/variations/getbrand");
    setBrands(data);
  };
  const fetchCount = async () => {
    const { data } = await apiClient.get("/products/count");
   
    setOof(data.countOutOfStock);
    setTotCount(data.countProduct);
  };

  const fetchProducts = async () => {
    if (catId || brandId) {
      setIsLoaded(true);
      const { data } = await apiClient.get("/products", {
        pageNumber: currentPageNo,
        category: catId,
        brand: brandId,
      });
      
      setPageCount(data.pageCount);
      setGetList(data.products);
      setIsLoaded(false);
    } else if (outOfStock === true) {
      setIsLoaded(true);
      const { data } = await apiClient.get("/products/outofstockproducts", {
        pageNumber: currentPageNo,
      });
      setPageCount(data.pageCount);
      setGetList(data.products);
      setIsLoaded(false);
    } else {
      setIsLoaded(true);
      const { data } = await apiClient.get("/products", {
        pageNumber: currentPageNo,
      });
      console.log(data)
      setPageCount(data.pageCount);
      setGetList(data.products);
      setIsLoaded(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/products", {
      pageNumber: currentPageNo,
      category: catId,
      brand: brandId,
    });
    setPageCount(data.pageCount);
    setGetList(data.products);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.delete("/products/delete", { id });
        if (result.ok) {
          swal("Delete Success");
          setDel(!del);
        }
      }
    });
  };

  const exportDataToExcel = async (ob) => {
    const { data } = await apiClient.get("/products/download");

    let arr = [];
    const flattenObj = (ob) => {
      let result = {};
      for (const i in ob) {
        if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
          const temp = flattenObj(ob[i]);

          delete temp.brand;
          delete temp.image;
          delete temp.email;
          delete temp.mobileNumber;
          delete temp.region;
          delete temp.zone;
          delete temp._id;

          for (const j in temp) {
            result[i + "." + j] = temp[j];
          }

          // result[i] = JSON.stringify(ob[i]);
        } else if (
          !Array.isArray(ob[i]) &&
          i != "updatedAt" &&
          i != "taxPrice" &&
          i != "__v"
        ) {
          // const nestedArr = ob[i];
          // nestedArr.forEach((obj, index) => {
          //   delete obj.image;
          //   const abc = flattenObj(obj);
          //   result[i + "." + index] = JSON.stringify(abc);
          // });

          if (i === "createdAt") {
            result[i] = new Date(ob[i]).toDateString();
          } else {
            result[i] = ob[i];
          }
        }
      }

      return result;
    };

    for (let i = 0; i < data?.length; i++) {
      const newobj = flattenObj(data[i]);
      console.log(newobj);
      arr.push(newobj);
    }

    setTimeout(() => {
      const data1 = arr;
      const worksheet = XLSX.utils.json_to_sheet(data1, {
        header: [
          "_id",
          "hsnCode",
          "groupId",
          "name",
          "brand",
          "size",
          "color",
          "flavour",
          "category",
          "subcategory",
          "specialcategory",
          "cost_price",
          "sell_price",
          "discount",
          "details",
          "description",
          "image",
          "weight",
          "countInStock",
          "manufacturer",
          "notes",
          "foodType",
          "limit",
          "shelflife",
          "fssai",
          "country",
          "expiry",
        ],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "ProductSheet.xlsx");
    }, 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">
              {outOfStock == true ? "Out of Stock Products" : "All Products"}
            </li>
          </ol>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <button
                onClick={() => {
                  setOutOfStock(true);
                  handleOutofStock();
                }}
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px",
                  backgroundColor: "orange",
                }}
              >
                Out of Stock
              </button>
            </div>
          </div> */}
          <div className="row" style={{ direction: "ltr" }}>
            <div className="col-lg-3 col-md-2">
              <div className="form-group">
                <label className="form-label">Product Brand</label>
                <Select
                  className="form-control"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={brandId}
                  label="Brand"
                  onChange={(e) => setBrandId(e.target.value)}
                >
                  {brands?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="form-group">
                <label className="form-label">Product Category</label>

                <Select
                  className="form-control"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={catId}
                  label="Category"
                  onChange={(e) => setCatId(e.target.value)}
                >
                  {cat?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>{" "}
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={handleSubmit}
            >
              Filter
            </button>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={() => {
                setBrandId();
                setCatId();
                setOutOfStock(false);
                window.location.reload();
              }}
            >
              Clear Filter
            </button>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={() => {
                setOutOfStock(true);
                handleOutofStock();
              }}
            >
              Out of Stocks
            </button>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={() => {
                exportDataToExcel();
              }}
            >
              Download Data
            </button>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Products (Total={totcount}) (Out of Stock={oof})</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>No.</th>
                        <th style={{ width: 100 }}>Image</th>
                        <th style={{ width: 200 }}>Name</th>

                        <th style={{ width: 150 }}>Category</th>

                        <th>Stock</th>

                        <th>Cost-Price</th>
                        <th>Sell-Price</th>
                        <th>Update</th>

                        <th>Dealer Price</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList.length > 0 ? (
                        getList?.map((row, index) => {
                          const imgsrc = `https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/${row?._id}.png`;

                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="cate-img-5">
                                  <img src={imgsrc} alt="product-name" />
                                </div>
                              </td>
                              <td
                                style={{ fontWeight: "500", fontSize: "16px" }}
                              >
                                {row?.name}
                              </td>

                              <td>{row?.category?.name}</td>

                              <td>{row?.countInStock}</td>

                              <td
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "green",
                                }}
                              >
                                ₹{row.cost_price}
                              </td>
                              <td
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  color: "green",
                                }}
                              >
                                ₹{row.sell_price}{" "}
                              </td>

                              <td className="action-btns">
                                <Link to="/product/update" state={row}>
                                  <Typography className="edit-btn">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      startIcon={<EditIcon />}
                                      color="green"
                                    >
                                      EDIT
                                    </Button>
                                  </Typography>
                                </Link>
                              </td>

                              <td>
                                <Link to="/product/dealerPrice" state={{ row }}>
                                  <Typography className="edit-btn">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      startIcon={<AddBusinessIcon />}
                                    >
                                      Dealer
                                    </Button>
                                  </Typography>
                                </Link>
                              </td>
                              <td>
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handlDeleteById(row._id)}
                                >
                                  <Button
                                    size="small"
                                    variant="contained"
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </Button>
                                </Typography>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <p style={{ width: "300%", fontSize: "30px" }}>
                          No Products found!
                        </p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllProducts;
