import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const DeliveryCharge = () => {
  const [limit, setLimit] = useState();
  const [charge, setCharge] = useState();
  const [dc, setDc] = useState();

  useEffect(() => {
    fetch();
  }, [limit, charge]);

  const fetch = async () => {
    const { data } = await apiClient.get("/variations/get-deliverycharge");
    setDc(data[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Change Charges",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post(
          "/variations/update-deliverycharge",
          {
            limit,
            charge,
            id: dc?._id,
          }
        );
        if (result.ok) {
          swal("Success");
        }

        setLimit("");
        setCharge("");
      }
    });
  };

  const navigate = useNavigate();

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Delivery Charge</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Variations</a>
            </li>
            <li className="breadcrumb-item active">Delivery Charge</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>
                    Current Charge ₹{dc?.charge} for Cart Value of ₹{dc?.limit}{" "}
                    or less
                  </h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Charge in ₹</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Delivery charge ₹"
                          name="charge"
                          value={charge}
                          onChange={(e) => setCharge(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Upper limit in ₹</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Upper limit ₹"
                          name="limit"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        />
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DeliveryCharge;
