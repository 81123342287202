import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const ExcelUpload = () => {
  const [file, setFile] = useState();
  const [file2, setFile2] = useState([]);
  const [file3, setFile3] = useState([]);
  const [file4, setFile4] = useState([]);
  // const [file5, setFile5] = useState([]);
  const [modal, setModal] = useState(false);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  function handleChange2(event) {
    setFile3(event.target.files[0]);
  }
  function handleChange3(event) {
    setFile4(event.target.files[0]);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Products",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const data = new FormData();
        data.append("uploadfile", file);
        const result = await apiClient.post("/upload", data);

        if (result.ok) {
          setModal(false);
          swal("Successfully Uploaded Products");
        } else {
          swal("Error! Retry");
          setModal(false);
        }
      }
    });
  };
  const handlesubmit3 = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Products",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const data = new FormData();
        data.append("uploadfile", file3);
        const result = await apiClient.post("/upload/updateStock", data);

        if (result.ok) {
          setModal(false);
          swal("Successfully Uploaded Products");
        } else {
          swal("Error! Retry");
          setModal(false);
        }
      }
    });
  };
  const handlesubmit4 = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to add Dealer price",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const data = new FormData();
        data.append("uploadfile", file4);
        const result = await apiClient.post("/upload/dealer-price", data);

        if (result.ok) {
          setModal(false);
          swal("Successfully Uploaded Dealer Prices");
        } else {
          swal("Error! Retry");
          setModal(false);
        }
      }
    });
  };
  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  const navigate = useNavigate();

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = await apiClient.post(
        "/uploadImages/uploadImages",
        formData,
        config
      );

      if (result.ok) {
        swal("Images Uploaded Successfully");
      } else {
        swal("Erro Retry!");
      }
    }
  };

  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div>Loading... Please wait!</div>
      ) : (
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Size</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button variant="contained" onClick={(e) => navigate("/")}>
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/product/create">Products</a>
              </li>
              <li className="breadcrumb-item active">Upload </li>
            </ol>

            {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>Upload Products(Excel) and Images</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Products List Sheet Excel
                          </label>
                          <form>
                            <input
                              type="file"
                              name="uploadfile"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={handleChange}
                            />
                          </form>
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Products Update File {" "}
                          </label>
                          <form>
                            <input
                              type="file"
                              name="uploadfile"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={handleChange2}
                            />
                          </form>
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handlesubmit3}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Dealer Price List Sheet Excel
                          </label>
                          <form>
                            <input
                              type="file"
                              name="uploadfile"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={handleChange3}
                            />
                          </form>
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handlesubmit4}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Bulk Images{" "}
                          </label>
                          <form>
                            <input
                              type="file"
                              multiple
                              onChange={uploadFileHandler}
                            />
                          </form>
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handlesubmit2}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default ExcelUpload;
