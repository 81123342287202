import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";
import Switch from "@mui/material/Switch";

const Reward = () => {
  useEffect(() => {
    getReward();
  }, []);
  const [checked, setChecked] = useState();
  const [ratio, setRatio] = useState();
  const [redeemPercent, setRedeemPercent] = useState();

  const getReward = async () => {
    const { data } = await apiClient.get("/variations/reward");
    console.log(data.reward)
    setChecked(data.reward.active)
    setRatio(data.reward.ratio)
    setRedeemPercent(data.reward.redeemPercent)
  };
  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Update?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/variations/reward", {
          active: checked,
          ratio,
          redeemPercent,
        });

        swal("Success");
        navigate("/");
      }
    });
  };

  const navigate = useNavigate();

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Reward</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a>Rewards</a>
            </li>
            <li className="breadcrumb-item active">Update Reward</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Reward Ratio (₹ 1 is equal to how many points)*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Reward Ratio"
                          name="ratio"
                          value={ratio}
                          onChange={(e) => setRatio(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Redeem Percentage</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="What percent of coins can be redeemed?"
                          name="redeemPercent"
                          value={redeemPercent}
                          onChange={(e) => setRedeemPercent(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">{checked ? "Active" : "Inactive"}</label>
                        <Switch
                          checked={checked}
                          onClick={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Reward;
