import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddSpecialCategory = () => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [id, setId] = useState();
  const [cat, setCat] = useState();
  const [sub, setSub] = useState();
  const [catId, setcatId] = useState("1");
  const [subCatId, setSubCatId] = useState("1");
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [photo, setPhoto] = useState();
  const [modal, setModal] = useState(false);
  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, []);

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const fetchSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-categories");
    setSub(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Special-Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const formData = new FormData();

        formData.append("image", photo, photo.name);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await apiClient.post(
          "/uploadSingleImage/uploadSingleImage",
          formData,
          config
        );

        const result = await apiClient.post("/variations/specialcategory", {
          id,
          name,
          description,
          photoLink: data,
          catId,
          subcatId: subCatId,
        });

        if (result.ok) {
          setModal(false);
          setId("");
          setName("");
          setDescription("");
          setPhoto("");
          setcatId("");
          setSubCatId("");
          swal("Sucess");
          navigate("/category/speciallist");
        } else {
          setModal(false);
          swal("Error Retry!");
        }
      }
    });
  };
  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };
  const catoptions = cat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const subcatoptions = sub?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const handleChange6 = async (selected) => {
    setcatId(selected.value);
  };
  const handleChange7 = async (selected) => {
    setSubCatId(selected.value);
  };
  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Category</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button
                  variant="contained"
                  onClick={(e) => navigate("/category/list")}
                >
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/product/create">Category</a>
              </li>
              <li className="breadcrumb-item active">Add Special-Category</li>
            </ol>

            {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>Add New Special-Category</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Special-Category Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Special-Category Id*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Id Number"
                            name="id"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Category </label>
                          <Select
                           className="basic-single"
                           classNamePrefix="select"
                           // defaultValue={}
                           isDisabled={isDisabled}
                           isLoading={isLoading}
                           isClearable={isClearable}
                           isRtl={isRtl}
                           isSearchable={isSearchable}
                           name="Category"
                           options={catoptions}
                           onChange={handleChange6}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">SubCategory</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="subCategory"
                            options={subcatoptions}
                            onChange={handleChange7}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Select Image </label>
                          <form>
                            <input
                              type="file"
                              // multiple
                              onChange={uploadFileHandler}
                            />
                          </form>
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            Special-Category Description*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Description"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default AddSpecialCategory;
