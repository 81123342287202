import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../api/client";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(cat ? cat[0]?._id : "1");
  const [subcat, setSubCat] = useState();
  const [subId, setSubId] = useState(subcat ? subcat[0]?._id : "1");
  const [specialcat, setSpecialCat] = useState();
  const [specialId, setSpecialId] = useState(
    specialcat ? specialcat[1]?._id : "1"
  );

  const [colors, setColors] = useState();
  const [colorId, setColorId] = useState();
  const [flavours, setFlavours] = useState();
  const [flavourId, setFlavourId] = useState();
  const [brands, setBrands] = useState();
  const [brandId, setBrandId] = useState();
  const [subbrands, setSubBrands] = useState();
  const [subbrandId, setSubBrandId] = useState();
  const [sizes, setSizes] = useState();
  const [sizeId, setSizeId] = useState();

  const navigate = useNavigate();

  const [state, setState] = useState({
    name: "",
    sku: "",
    groupId: "",
    image: "",
    manufacturer: "",
    weight: "",
    description: "",
    details: "",
    cost_price: "",
    sell_price: "",
    discount: "",
    countInStock: "",
    notes: "",
    hsnCode: "",
    foodType: "",
    limit: "",
    fssai: "",
    country: "",
    expiry: "",
    shelflife: "",
    customercare: "",
    units: "",
  });

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    fetchSpecialCategories();
    fetchColors();
    fetchFlavours();
    fetchBrands();
    fetchSubBrands();
    fetchSizes();
  }, []);

  const fetchColors = async () => {
    const { data } = await apiClient.get("/variations/getcolor");
    setColors(data);
  };

  const fetchFlavours = async () => {
    const { data } = await apiClient.get("/variations/getflavour");
    setFlavours(data);
  };

  const fetchBrands = async () => {
    const { data } = await apiClient.get("/variations/getbrand");
    setBrands(data);
  };
  const fetchSubBrands = async () => {
    const { data } = await apiClient.get("/variations/get-sub-brand");
    setSubBrands(data);
  };

  const fetchSizes = async () => {
    const { data } = await apiClient.get("/variations/getsize");
    setSizes(data);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const fetchSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-categories");
    setSubCat(data);
  };

  const fetchSpecialCategories = async () => {
    const { data } = await apiClient.get("/variations/get-special-categories");

    setSpecialCat(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/products/create", {
          name: state.name,
          sku: state.sku,
          groupId: state.groupId,
          image: state.image,
          color: colorId,
          flavour: flavourId,
          brand: brandId,
          subBrand: subbrandId,
          category: catId,
          subcategory: subId,
          specialcategory: specialId,
          manufacturer: state.manufacturer,
          size: sizeId,
          weight: state.weight,
          description: state.description,
          details: state.details,
          cost_price: state.cost_price,
          sell_price: state.sell_price,
          discount: state.discount,
          countInStock: state.countInStock,
          notes: state.notes,
          hsnCode: state.hsnCode,
          foodType: state.foodType,
          limit: state.limit,
          expiry: state.expiry,
          country: state.country,
          fssai: state.fssai,
          shelflife: state.shelflife,
          customercare: state.customercare,
          units: state.units,
        });
        if (result.ok) {
          setState({
            name: "",
            sku: "",
            groupId: "",
            image: "",
            manufacturer: "",
            weight: "",
            description: "",
            details: "",
            cost_price: "",
            sell_price: "",
            discount: "",
            countInStock: "",
            notes: "",
            hsnCode: "",
            foodType: "",
            limit: "",
            fssai: "",
            country: "",
            expiry: "",
            shelflife: "",
            customercare: "",
            units: "",
          });
          swal("Success product added");
          navigate("/product/list");
        }
      }
    });
  };

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = await apiClient.post(
        "/uploadImages/uploadImages",
        formData,
        config
      );
      if (result.ok) {
        swal("Images Uploaded Successfully");
        setFile2([]);
      } else {
        swal("Erro Retry!");
      }
    }
  };

  const coloroptions = colors?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const flavouroptions = flavours?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const brandoptions = brands?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const subbrandoptions = subbrands?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const sizeoptions = sizes?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const catoptions = cat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const subcatoptions = subcat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const specialoptions = specialcat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const handleChange1 = async (selected) => {
    setColorId(selected.value);
  };
  const handleChange2 = async (selected) => {
    setFlavourId(selected.value);
  };
  const handleChange3 = async (selected) => {
    setBrandId(selected.value);
  };
  const handleChange4 = async (selected) => {
    setSubBrandId(selected.value);
  };
  const handleChange5 = async (selected) => {
    setSizeId(selected.value);
  };
  const handleChange6 = async (selected) => {
    setCatId(selected.value);
  };
  const handleChange7 = async (selected) => {
    setSubId(selected.value);
  };
  const handleChange8 = async (selected) => {
    setSpecialId(selected.value);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Products</a>
            </li>
            <li className="breadcrumb-item active">Add Product</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Product</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Name*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product SKU*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product SKU"
                            name="sku"
                            value={state.sku}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Group Id*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group Id"
                            name="groupId"
                            value={state.groupId}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">HSN Code*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="hsnCode"
                            value={state.hsnCode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Food Type* (VEG or NONVEG)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="foodType"
                            value={state.foodType}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">No. of Images*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="image"
                            value={state.image}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Color*</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            options={coloroptions}
                            onChange={handleChange1}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Flavour*</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="flavour"
                            options={flavouroptions}
                            onChange={handleChange2}
                          />
                            
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Brand*</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="brand"
                            options={brandoptions}
                            onChange={handleChange3}
                          />
                           
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Sub Brand*
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="Sub Brand"
                            options={subbrandoptions}
                            onChange={handleChange4}
                          />
                            
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Size*</label>
                          <Select
                           className="basic-single"
                           classNamePrefix="select"
                           // defaultValue={}
                           isDisabled={isDisabled}
                           isLoading={isLoading}
                           isClearable={isClearable}
                           isRtl={isRtl}
                           isSearchable={isSearchable}
                           name="Size"
                           options={sizeoptions}
                           onChange={handleChange5}
                          />
                            
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Category*
                          </label>

                          <Select
                           className="basic-single"
                           classNamePrefix="select"
                           // defaultValue={}
                           isDisabled={isDisabled}
                           isLoading={isLoading}
                           isClearable={isClearable}
                           isRtl={isRtl}
                           isSearchable={isSearchable}
                           name="Category"
                           options={catoptions}
                           onChange={handleChange6}
                          />
                            
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Sub-Category*
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="subCategory"
                            options={subcatoptions}
                            onChange={handleChange7}
                          />
                            
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Special-Category*
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="specialCategory"
                            options={specialoptions}
                            onChange={handleChange8}
                          />
                            
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Manufacturer*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Manufacturer Name"
                            name="manufacturer"
                            value={state.manufacturer}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Cost Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product C.P"
                            name="cost_price"
                            value={state.cost_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Sell Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product S.P"
                            name="sell_price"
                            value={state.sell_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Weight*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Weight"
                            name="weight"
                            value={state.weight}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Count In Stock*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Stock"
                            name="countInStock"
                            value={state.countInStock}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Customer Care</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile or Email"
                            name="customercare"
                            value={state.customercare}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">FSSAI*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="FSSAI"
                            name="fssai"
                            value={state.fssai}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Country of Origin*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            name="country"
                            value={state.country}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Expiry*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Expiry"
                            name="expiry"
                            value={state.expiry}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Shelf Life*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Shelf Life"
                            name="shelflife"
                            value={state.shelflife}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Purchase Limit*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Limit"
                            name="limit"
                            value={state.limit}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Units/Contents</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Units"
                            name="units"
                            value={state.units}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Description*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Description"
                            name="description"
                            value={state.description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Details*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Details"
                            name="details"
                            value={state.details}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-2"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="form-group">
                        <label className="form-label">
                          Admin Notes* (for refrence)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Select images for the above product
                        </label>
                        <form>
                          <input
                            type="file"
                            multiple
                            onChange={uploadFileHandler}
                          />
                        </form>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handlesubmit2}
                    >
                      Upload Images
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddProduct;
