import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import DatePicker from "react-date-picker";
import SendIcon from "@mui/icons-material/Send";
import DownloadIcon from '@mui/icons-material/Download';
import apiClient from "../../api/client";
import * as XLSX from "xlsx";

const TopCustomers = () => {
  const [isloaded, setIsLoaded] = useState(false);

  const [id, setId] = useState();
  const [value1, onChange1] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  const [getList, setGetList] = useState([]);

  const navigate = useNavigate();

 

  const fetchProducts = async () => {
    
   
    setIsLoaded(true);
    const { data } = await apiClient.get("/users/top", {
        startDate: value1,
        endDate: value2,
    });
   
   
    setGetList(data);
    setIsLoaded(false);
  };

  const download = async (ob) => {
   
    setTimeout(() => {
        let Heading = [['ID', 'Name', "Phone", 'Orders Made']];
        const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, Heading);

    
    XLSX.utils.sheet_add_json(worksheet, getList, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "TopCustomersSheet.xlsx");
    }, 1000);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Delivery Slots</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Slots</li>
          </ol>
          <div className="row">
            <div style={{marginleft: "30px"}}>
          <DatePicker value={value1} onChange={onChange1} />

            </div>
            <div style={{marginLeft: "200px"}}>

          <DatePicker value={value2} onChange={onChange2} />
            </div>

            <Typography
                  className="delete-btn"
                  onClick={(e) => fetchProducts()}
                  style={{marginLeft: "20px"}}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<SendIcon />}
                  >
                    Check
                  </Button>
                </Typography>
            <Typography
                  className="delete-btn"
                  onClick={(e) => download()}
                  style={{marginLeft: "20px"}}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<DownloadIcon />}
                  >
                    Download Excel
                  </Button>
                </Typography>

          </div>
          
          <div className="col-lg-8 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>Top Products</h4>
                
                
              </div>

              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>No.</th>
                        <th style={{ width: 250 }}>ID</th>
                        <th style={{ width: 250 }}>Phone</th>
                        
                        <th style={{ width: 400 }}>Name</th>
                        <th style={{ width: 300 }}>Number of Orders</th>

                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList?.length > 0
                        ? getList?.map((row, index) => {
                            
                            return(
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{row._id}</td>
                              <td>{row.phone}</td>
                              
                              <td>{row.name}</td>
                              <td>{row.sum}</td>

                              {/* <td className="action-btns">
                                
                              </td> */}
                            </tr>)
})
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TopCustomers;
