import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

const AllTheme = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchBrands();
  }, [del]);

  const fetchBrands = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/theme/all");

    setGetList(data);
    setIsLoaded(false);
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Themes</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Variations</li>
            <li className="breadcrumb-item active">Theme</li>
          </ol>
          <div className="col-lg-8 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2"></div>

              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Primary Color</th>
                        <th>Secondary Color</th>
                        <th>Tertiary Color</th>
                        <th>Light Gray Color</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList.length > 0
                        ? getList?.map((row, index) => (
                            <tr key={index}>
                              <td style={{ backgroundColor: row.firstcolor }}>
                                {row.firstcolor}
                              </td>
                              <td style={{ backgroundColor: row.secondcolor }}>
                                {row.secondcolor}
                              </td>
                              <td style={{ backgroundColor: row.thirdcolor }}>
                                {row.thirdcolor}
                              </td>
                              <td style={{ backgroundColor: row.fourthcolor }}>
                                {row.fourthcolor}
                              </td>

                              <td className="action-btns">
                                <Link to="/add-theme" state={row}>
                                  <Typography className="edit-btn">
                                    <i className="fas fa-edit" />
                                  </Typography>
                                </Link>
                              </td>
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllTheme;
