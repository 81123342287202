import React, { useState } from "react";
import {
  Button,
  IconButton,
  InputBase,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import swal from "sweetalert";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Paper from "@material-ui/core/Paper";
import "./Pincode.css";

import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

const intialState = {
  areas: [{ name: "" }],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const AddPincode = () => {
  const [name, setName] = useState();
  const [jobsData, setJobsData] = useState(intialState);

  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Pincode",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/pincode/create", {
          name,
          area: jobsData.areas,
        });
        if (result.ok) {
          swal("Success");
        }

        setName("");
        setJobsData(null);
        navigate("/list-pincodes");
      }
    });
  };

  const navigate = useNavigate();

  const handleChange = (index, e) => {
    const values = [...jobsData.areas];
    values[index][e.target.name] = e.target.value;
    setJobsData({ ...jobsData, areas: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      areas: [...prevState.areas, { name: "" }],
    }));
  };

  const handleRemoveField = (index) => {
    const values = jobsData.areas;
    values.splice(index, 1);
    setJobsData((prevState) => ({ ...prevState, values }));
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Pincode</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/list-pincodes")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Pincode</a>
            </li>
            <li className="breadcrumb-item active">Add Pincode</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Pincode</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Pincode</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter pincode here"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <br />
                    <div>
                      <TableContainer
                        component={Paper}
                        className="tb-container"
                      >
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Area Name</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {jobsData?.areas?.map((itemField, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    type="text"
                                    name="name"
                                    onChange={(e) => handleChange(index, e)}
                                    value={itemField.name}
                                    placeholder="Area Name"
                                  />
                                </TableCell>

                                <TableCell align="left">
                                  <IconButton
                                    onClick={() => handleRemoveField(index)}
                                  >
                                    <DeleteOutlinedIcon
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="addButton">
                        <button onClick={handleAddField}>+</button>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddPincode;
