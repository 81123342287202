import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const AllHeading = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchSizes();
  }, [del]);

  const fetchSizes = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/heading/all");
    setGetList(data);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/heading/delete", { id });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Headings</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
          </ol>
          <div className="col-lg-8 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              {/* <div className="card-title-2">
                <h4>All Heading *(Total Heading {getList.length} )</h4>
                <Button
                  style={{ marginLeft: "220px" }}
                  variant="contained"
                  color="success"
                  onClick={(e) => navigate("/add-heading")}
                >
                  <AddIcon /> Add Heading
                </Button>
              </div> */}

              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>No.</th>

                        <th>Screen</th>
                        <th>Heading</th>
                        <th>Action</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList.length > 0
                        ? getList?.map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td>{row.name}</td>
                              <td>{row.headingname}</td>

                              <td className="action-btns">
                                <Link to="/update-heading" state={row}>
                                  <Typography className="edit-btn">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      startIcon={<EditIcon />}
                                      color="green"
                                    >
                                      UPDATE
                                    </Button>
                                  </Typography>
                                </Link>
                              </td>
                              <td>
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handlDeleteById(row._id)}
                                >
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </Button>
                                </Typography>
                              </td>
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllHeading;
