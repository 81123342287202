import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "../api/client";

const DealerPrice = () => {
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [price, setPrice] = useState();
  const [margin, setMargin] = useState();
  const [getList, setGetList] = useState();
  const [del, setDel] = useState();
  const [isloaded, setIsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  const product = location.state?.row;

  useEffect(() => {
    fetchBrands();
  }, [del, refresh]);

  const fetchBrands = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get(
      "/products/getdealerpricebyproductid",
      { productId: product._id }
    );

    setGetList(data);
    setIsLoaded(false);
  };
  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/products/deletedealerprice", {
      id,
    });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add Dealer Price",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      var mr = 0;
      mr = Number(1 - price / margin).toFixed(2) * 100;

      if (success) {
        const result = await apiClient.post("/products/createdealerprice", {
          min,
          max,
          price,
          product,
          margin: mr,
        });
        if (result.ok) {
          swal("Success");
          setRefresh(!refresh);
        }

        setMin("");
        setMax("");
        setPrice("");
        setMargin("");
        // navigate("/list-coupens");
      }
    });
  };

  const navigate = useNavigate();

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Product</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/list-variations/brand")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>

            <li className="breadcrumb-item active">Add Dealer Price</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Dealer Price</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Minimum Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Min Qty"
                            name="min"
                            value={min}
                            onChange={(e) => setMin(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Maximum Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Max qty"
                            name="max"
                            value={max}
                            onChange={(e) => setMax(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Price ₹</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Price"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">MRP</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="MRP"
                            name="margin"
                            value={margin}
                            onChange={(e) => setMargin(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Min Qty</th>
                        <th>Max Qty</th>
                        <th>Margin</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList?.length > 0
                        ? getList?.map((row, index) => (
                            <tr key={index}>
                              <td>{row._id}</td>
                              <td>{row.min}</td>
                              <td>{row.max}</td>
                              <td>{row.margin}%</td>
                              <td>₹{row.price}</td>

                              <td className="action-btns">
                                <Typography
                                  className="delete-btn"
                                  onClick={(e) => handlDeleteById(row._id)}
                                >
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                  >
                                    Delete
                                  </Button>
                                </Typography>
                              </td>
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DealerPrice;
