import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import DatePicker from "react-date-picker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import apiClient from "../../api/client";

const DeliverySlot = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [id, setId] = useState();
  const [value1, onChange1] = useState(new Date());
  const [getList, setGetList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchPincode();
  }, [del, value1]);

  const fetchPincode = async () => {
    
    let formattedDate = `${
      value1.getMonth() + 1
    }-${value1.getDate()}-${value1.getFullYear()}`;
    setIsLoaded(true);
    const { data } = await apiClient.get("/variations/get-deliveryslot", {
      date: formattedDate,
    });
 
    setId(data[0]._id);
    setGetList(data[0].slots);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/variations/delete-deliveryslot", {
      id,
    });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Delivery Slots</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Slots</li>
          </ol>
          <DatePicker value={value1} onChange={onChange1} />
          <div className="col-lg-8 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                {/* <h4>All Slots *(Total Slots {getList.length} )</h4> */}
                <Typography
                  className="delete-btn"
                  onClick={(e) => handlDeleteById(id)}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Typography>
                <Button
                  style={{ marginLeft: "220px" }}
                  variant="contained"
                  color="success"
                  onClick={(e) => navigate("/add-deliveryslot")}
                >
                  <AddIcon /> Add Delivery Slot
                </Button>
                {getList?.length > 0 ? (<Link
                                  to="/update-deliveryslot"
                                  state={{ getList, id }}
                                ><Button
                  style={{ marginLeft: "20px" }}
                  variant="contained"
                  color="success"
                 
                >
                  <AddIcon /> Update
                </Button></Link>) : <></>}
              </div>

              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Slot</th>
                        <th>Quantity</th>

                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList?.length > 0
                        ? getList?.map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td>{row.slot}</td>
                              <td>{row.quantity}</td>

                              {/* <td className="action-btns">
                                
                              </td> */}
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DeliverySlot;
