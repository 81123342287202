import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import apiClient from "./../../api/client";
import swal from "sweetalert";
import { Typography } from "@mui/material";

const Dealers = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [getList, setGetList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, [refresh]);

  const fetchCategories = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/users/getdealers");
    setGetList(data);
    setIsLoaded(false);
  };

  const handleRemoveDealer = async (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to Remove Dealership ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/users/removedealer", {
          userId: item._id,
        });
        if (result.ok) {
          setRefresh(!refresh);
          swal("Customer Removed from dealership");
        } else {
          swal("Error Retry");
        }
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Dealers</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Dealers</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Dealers({getList?.length})</h4>
              </div>
              {getList?.length > 0 ? (
                <div className="card-body-table">
                  <div className="table-responsive">
                    <table className="table ucp-table table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: 60 }}>No.</th>
                          <th>Name</th>
                          {/* <th>Email</th> */}
                          <th>Mobile</th>
                          <th>Send Notification</th>

                          <th>Remove Dealer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isloaded !== true
                          ? getList.map((row, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{row.name}</td>
                                {/* <td>{row.email}</td> */}

                                <td>{row.phone}</td>
                                <td className="action-btns">
                                  <Link to="/customer/notification" state={row}>
                                    <Typography className="edit-btn">
                                      🔔
                                    </Typography>
                                  </Link>
                                </td>

                                <td>
                                  <Typography
                                    onClick={(e) => handleRemoveDealer(row)}
                                  >
                                    <i className="fas fa-edit" />
                                  </Typography>
                                </td>
                              </tr>
                            ))
                          : "Loading..."}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <h4 style={{ textAlign: "center", marginTop: "50px" }}>
                  No Data found
                </h4>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dealers;
